
.team-section-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4rem; /* Adjust the gap between service items */
    padding: 30px;
  }
  
  .team-section-bottom {
   
    width: 400px;
    background-color: rgba(221, 221, 238, 0.192);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1rem;
    text-align: center;
    margin-bottom: 2rem; /* Adjust the margin between service items */
    box-shadow: 0px 8px 5px rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;
    padding-top: 10px;
    border: 0.1px solid #f7f7f7;
    
  }

  .team-section-bottom:hover{
    transform: translateY(-5px);
    background-color:  rgba(221, 221, 238, 1);; 
  }
  
  .team-section-bottom img {
    width: 200px; /* Ensure the image fills its container */
    height: 200px;
   border-radius: 50%;
    padding-bottom: 5px;
  }
  

  .team-section-bottom p {
    margin: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 1.5;
  }
  .team-primary-heading {
    font-size: 24px;
    color: rgb(3, 67, 131);
    /* line-height: 5rem; */
    max-width: 600px;
    margin: 0 auto; /* Center the heading horizontally */
    text-align: center; /* Align text to the center */
  }

  
  .team-social-midea {
    display: flex;
  }
  
  .team-social-midea a {
    color: rgb(3, 67, 131);
    font-size: 30px;
    margin-right: 15px;
    transition: transform 0.3s ease;
  }

  .team-social-midea a:hover {
    transform: translateY(-3px);
  }
  
  @media (max-width: 900px) {
    .team-section-bottom {
      flex-basis: 45%; /* For smaller screens, each item takes up 45% width */
    }
  }
  
  @media (max-width: 600px) {
    .team-section-bottom {
      flex-basis: 100%; /* On even smaller screens, each item takes up 100% width (full width) */
    }
  }
  