.container {
  margin-top: 20px;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 40px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .imageContainer {
    text-align: center;
    margin-bottom: 20px;
    padding-top: 20px;
  }
  
  .image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .descriptionContainer {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
  }
  
  .description {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
    text-align: justify;
  }

  @media(min-width:1000px){
    .imageContainer{
      height: 400px;
    }
    .image{
      height: 100%;
    }
    
  }
  