
.focus-area-container {
    display: flex;
    align-items: flex-start;
    background: linear-gradient(45deg, rgb(124, 179, 235),rgb(1, 24, 46));
    border-radius: 1rem;
    border-bottom-left-radius: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 40px;
    padding: 30px;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
  }
  
  .focus-area-container:hover {
    transform: translateY(-1rem);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background: linear-gradient(45deg, rgb(1, 24, 46), rgb(117, 179, 241));
  }
  
  .focus-area-container img:first-child {
    width: 30%;
    margin-right: 2rem;
  }
  
  .focus-area-content {
    flex: 1;
  }
  
  .focus-area-content h3 {
    font-size: 2rem;
    color: #ffffff;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    margin-left: 1rem;
    align-items: center;
  }
  
  .focus-area-content .focus-list {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .focus-area-content .focus-list img {
    width: 35px;
    margin-right: 1rem;
  }
  
  .focus-area-content p {
    font-size: 1.6rem;
    color: #ffffff;
    line-height: 1.6;
    margin: 0;
  }

    /* Media Query for Smaller Devices */
    @media (max-width: 900px) {
        .focus-area-container {
          flex-direction: column;
          align-items: center;
          margin: 20px;
          padding: 10px;
        }

        .focus-area-container img{
          width: 50%;
        }

        
      
        
      }
  