.footer {
    background-color: rgb(3, 67, 131);
    color: #fff;
    padding: 40px 20px; /* Adjust padding */
    position: relative; /* Ensure relative positioning for absolute child */
    margin-top: 20px;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    flex-direction: row;
    padding: 20px 40px; /* Adjust padding */
  }

  .footer-logo{
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
  }
  
  .footer-logo img {
    width: 100px; /* Adjust size as needed */
    height: 100px;
    box-shadow: 0px 5px 10px rgba(255, 255, 255,1);
    border-radius: 50%;
    margin-bottom: 1rem;
  }

  .footer-logo img:hover {
    
    box-shadow: 0px 5px 10px rgba(255, 255, 255,0.5);
    
  }
  /* footer contact information*/
  .footer-contact-info-container{
    display: flex;
    position:relative;
    flex-direction: column;
    gap: 1rem;
    border-left: 1px solid rgb(241, 191, 26);
    padding-left: 15px;
  }

  .footer-address-container{
    display: flex;
    position: relative;
    flex-direction: row;
    gap: 1rem;
    
  }

  .address-icon{
    font-size: clamp(1.5rem, 3vw, 2rem);
  }


  .footer-links{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    padding-left: 1rem;
  }
  .footer-links a {
    color: #fff;
    text-decoration: none;
    margin-right: 20px;
    font-size: clamp(1rem, 3vw, 1.5rem);
    transition: color 0.3s ease;
    border: 0.5px solid  rgb(241, 191, 26);
    padding:0.5rem;

  }
  
  .footer-links a:hover {
    color: rgb(241, 191, 26);
  }
  
  .footer-social {
    display: flex;
  }
  
  .footer-social a {
    color: #fff;
    font-size: clamp(2rem, 3vw, 2.5rem);
    margin-right: 15px;
    transition: transform 0.3s ease;
  }
  
  .footer-social a:hover {
    transform: translateY(-3px);
  }
  
  .go-to-top-btn {
    /*background-color: #057417;*/
    background-color: rgb(3, 67, 131);
    color: #ffffff;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
    cursor: pointer;
    position: fixed; /* Change to fixed positioning */
    bottom: 20px;
    right: 20px;
    z-index: 999; /* Ensure the button is above other content */
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px rgba(0,0,0,1);
  }
  
  .go-to-top-btn:hover {
    transform: translateY(-3px);
  }
  
  
  
  .footer-bottom {
    display: flex;
    justify-content: center; 
    align-items: center;
    background-color: rgb(5, 30, 54);
    height: 50px; 
}

.footer-primary-title{
  
  text-decoration: underline;
}

.footer-primary-text{
  font-size: clamp(1.25rem, 3vw, 1.5rem);
}

.footer-bottom p {
    font-size: clamp(1rem, 3vw, 1.5rem);;
    color: white;
    text-align: center; 
}

@media(max-width:900px){
  .footer-container {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 2rem;
    padding: 100px, 10px;
  }
}
