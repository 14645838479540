@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --clr-primary: #034383;
  --clr-primar-light: #333547;
  --clr-secondary: #092541;
  --clr-white: #fff;
  --clr-black: #000;
  --clr-pumpkin: #F1BF1A;
  --clr-silver: #a8a5a6;
  --clr-silver-v1: #bdbabb;
  --clr-scarlet: #fe1e00;
  --clr-scarlet-v1: rgb(254, 30, 0, 0.79);
  --clr-green: #00fe93;
  --clr-yellow: #fec80a;
  --clr-jet: #034383;
  --clr-peach: #ffc397;
  --font-family-bai: "Bai Jamjuree", sans-serif;
  --transition-default: all 300ms ease-in-out;
}

body {
  font-family: var(--font-family-bai);
}

ul {
  list-style-type: none;
}

a {
  color: unset;
  text-decoration: none;
  color: var(--clr-white);
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.img-fit-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-white {
  color: var(--clr-white);
}

.text-silver-v1 {
  color: var(--clr-silver-v1);
}

.text-scarlet {
  color: var(--clr-scarlet);
}

.text-green {
  color: var(--clr-green);
}

.bg-jet {
  background: var(--clr-jet);
}

.text-sm {
  font-size: 14px;
}

.app {
  display: flex;
  min-height: 100vh;
  background: rgb(219, 225, 231);
}

/* commons */
.lg-value {
  font-size: 24px;
  font-weight: 600;
}

.grid-common {
  background-color: var(--clr-primary);
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 8px 24px;
  color: var(--clr-white);
  padding: 24px;
}

.grid-c-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.grid-c-title-text {
  font-weight: 400;
  color: var(--clr-silver);
}

.grid-c-title-icon img {
  width: 24px;
}

/*Content create css */
.create-content-container {
  max-width: 600px;
  min-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-bottom: 20px;
  height: 100%;
}

.content-primary-header {
  text-align: center;
}

.content-form-group {
  margin-bottom: 20px;
}

.content-primary-label {
  display: block;
  margin-bottom: 5px;
}

input[type='text'],
input[type='file'] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.date-picker {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}

.content-primary-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #034383;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.content-primary-button:hover {
  background-color: #083563;
}



.content-editor-container {
  border: 1px solid #ccc;
  border-radius: 4px;
  min-height: 200px;
}

.selected-image {
  display: block;
  width: 100px;
  height: auto;
  margin-top: 10px;
}


/*Content View css */
.admin-content-view-container {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create-more-content-button {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: #034383;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.create-more-content-button:hover {
  background-color: #052c53;

}

.create-more-content-button svg {
  margin-right: 5px;
}

.content-body-container {
  display: flex;
  flex-wrap: wrap;

}

.content-view-item {
  display: flex;
  position: relative;
  margin: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 300px;
  flex-direction: row;
  width: 800px;
}

.view-content-image-container {
  width: 300px;
  height: 200px;
}

.view-content-image-container img {
  width: 300px;
  height: 200px;
  display: block;
}

.view-content-details {
  padding: 20px;
  width: 500px;
}

.view-content-details h2 {
  margin: 0;
}

.content-actions {
  margin-top: 10px;
}

.content-edit-button,
.content-delete-button {
  background-color: #dd0a0a;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin-right: 10px;
  cursor: pointer;
}


.content-edit-button {
  background-color: #034383;

}


.content-delete-button:hover {
  background-color: #b30808;
}

.content-edit-button:hover {
  background-color: #042342;
}


@media screen and (max-width: 1000px) {
  .grid-common {
    padding: 16px;
  }

  body {
    font-size: 14px !important;
  }

  .content-view-item {

    flex-direction: column;
    width: 100%;
  }

  .view-content-details {
    width: 100%;
  }

  .view-content-image-container {
    width: 100%;
    height: 90%;
  }

  .view-content-image-container img {
    width: 100%;
    height: 90%;
  }
}