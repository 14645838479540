/* Basic Styling */
.about-page-style {
  font-family: Arial, sans-serif;
  color: #333;

}

.the-banner {
  position: relative;
  height: 550px;
  overflow: hidden;


}


.the-banner img {
  width: 100%;
  object-fit: cover;
  height: 550px;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;

}

.the-banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 45px;
  font-weight: bold;
  color: white;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.the-about-content-container {
  display: flex;
  justify-content: space-between;
  margin: 50px 0;
}

.the-about-left-section img {
  width: 600px;
  max-width: 700px;
  height: 500px;
  border-radius: 0.5rem;
  margin: 0 1rem;
}

.the-about-right-section {
  flex: 1;
  padding: 0 20px;
}

.the-team-member {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.the-member-info {
  margin-left: 20px;
}

.the-core-values {
  margin-top: 50px;
}

/* Styling for Links */
.about-a {
  text-decoration: none;
  color: #007bff;
}

.about-a:hover {
  text-decoration: underline;
}


@media(max-width:1000px) {
  .the-about-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
  }

  .the-about-left-section img {
    width: 100%;
    height: 300px;
    border-radius: 0.5rem;

    align-items: center;
  }

  .the-about-right-section {
    margin: 10px 10px;
  }
}