.admin-login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .admin-login-form {
    background: white;
    padding: 60px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width:350px;
  }
  
  .admin-icon {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }
  
  .admin-h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .admin-input {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .admin-submit-button {
    width: 100%;
    padding: 10px;
    background-color:rgb(3, 67, 131);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .admin-button:hover {
    background-color:rgb(4, 50, 97);
  }
  
  .error-message {
    color: red;
    margin: 10px 0;
  }
  