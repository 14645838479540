.priority-container {
  margin-top: 40px;
  margin: 40px;
  padding: 30px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.section-title {
  text-align: center;
  font-size: 24px;
  color: rgb(3, 67, 131);
  margin-bottom: 20px;
}

.priority-item {
  display: flex;
  align-items: flex-start;
  /* Align items at the start of flex container */
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  /* Add a bottom border to separate items */
  padding-bottom: 20px;
  /* Add padding at the bottom of each item */
}

.item-content {
  flex: 1;
  /* Expand to fill available space */
  display: flex;
  flex-direction: column;
  /* Stack header and paragraph vertically */
}

.priority-item h3 {
  flex: 0 0 25%;
  /* 25% width for the header */

  color: rgb(3, 67, 131);
  font-size: 20px;
  margin-right: 20px;
  /* Add margin to separate header from paragraph */
}

.priority-item p {
  flex: 1;
  /* Take up remaining space */
  font-size: clamp(1rem, 3vw, 1.5rem);
  line-height: 1.6;
  color: #666;
}

.custom-list {
  margin-bottom: 8px;
  /* Optional: Add margin for spacing */
  list-style-type: circle;
}

.custom-list li {
  margin-bottom: 5px;
  /* Optional: Adjust spacing between list items */
}

.priority-comp-secondary-heading {
  font-size: clamp(1.4rem, 2.5vw, 2rem);
  color: rgb(3, 67, 131);
  /* line-height: 5rem; */
  max-width: 600px;
  /* Center the heading horizontally */
  /* Align text to the center */
}
@media(max-width:900px){
  .priority-container {
    margin:20px;
  }
}