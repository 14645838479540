/* grid c3 */
.grid-c3 {
  display: flex;
  flex-direction: column;
}
.grid-c3-content {
  flex: 1;
}
.grid-chart {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: stretch;
  height: 100%;
}
.chart-vert-value {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 28px;
}
.grid-chart-bar {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.grid-chart-bar .bar-wrapper {
  flex: 1;
  width: 32px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 10px;
  background-color: var(--clr-primar-light);
  position: relative;
  overflow: hidden;
}
.grid-chart-bar .grid-hortz-value {
  margin-top: 12px;
}
.grid-chart-bar .bar-item1 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--clr-pumpkin);
  border-radius: 10px;
}
.grid-chart-bar .bar-item2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: var(--clr-peach);
  border-radius: 10px;
}
.grid-chart-bar:nth-child(3) .bar-wrapper .bar-item1 {
  z-index: 1;
}

@media screen and (max-width: 992px) {
  .grid-c3 {
    grid-column-start: 1;
    grid-column-end: 3;
    height: 240px;
  }
}
