/* grid c4 */
.grid-c4 .grid-c-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.grid-c4-content {
  margin-top: 20px;
  border-radius: 16px;
}
.grid-c4 .grid-c4-content {
  padding: 12px;
}
.grid-c4 .grid-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
}
.grid-c4 .grid-item-l {
  display: flex;
  align-items: flex-start;
  column-gap: 16px;
}
.grid-c4 .grid-item-l .icon {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.grid-c4 .grid-item-l .icon img {
  width: 20px;
}
.grid-c4 .grid-item-l .text {
  font-weight: 600;
}
.grid-c4 .grid-item-l .text span {
  font-weight: 300;
  font-size: 15px;
  display: block;
  margin-top: 5px;
  opacity: 0.9;
}
