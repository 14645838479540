.testimonial-slider-container {
    width: 80%; /* Adjust width as needed */
    margin: 0 auto;
    padding: 30px;
  }

  .section-title {
    text-align: center;
    font-size: 24px;
    color: rgb(3, 67, 131);
    margin-bottom: 20px;
  }
  
  .testimonial-header{
    text-align: center;
    font-size: 14px;
    color: rgb(3, 67, 131);
    margin-bottom: 20px;
  }
  .testimonial-slide {
    text-align: center;
  }
  
  .testimonial-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 auto 20px;
  }
  
  .testimonial-quote {
    font-size: 18px;
    line-height: 1.5;
    color: #333333;
    margin-bottom: 10px;
  }
  
  .testimonial-author {
    font-style: italic;
    color: #666666;
    font-size: 14px;
  }
  