/*
  Color Brand used in the webiste
  1.rgb(3, 67, 131); blue
  2.rgb(241, 191, 26) yellow;*/
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* page after banner*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Reem Kufi", sans-serif !important;
}

img {
  max-width: 100%;
  height: auto;
}

.App {
  min-height: 100vh;
  width: 85vw;
  max-width: 1900px;
  margin: 0rem auto;
}

body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background-color: #ffffff;
}

/*
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
}
.navbar-menu-container {
  display: none;
}
.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  color: black;
  font-size: 1.1rem;
  font-weight: 600;
  .navbar-cart-icon {
  font-size: 1.15rem;
}
.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
}
}*/

.primary-button {
  padding: 0.9rem 1.75rem;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}

.primary-button:hover {
  background-color: rgb(234, 234, 234);
}

.home-about-container {
  position: relative;
  display: flex;
  padding-top: 10rem;
  padding-left: 5px;
}

.home-bannerImage-container {
  position: absolute;
  top: -100px;
  right: -170px;
  z-index: -2;
  max-width: 700px;
}

.home-about-image-section {
  max-width: 600px;
  flex: 1;
}

.home-text-section {
  flex: 4;
  display: flex;
  flex-direction: column;

  align-items: center;
}

.primary-heading {
  font-size: clamp(1.7rem, 2.5vw, 3.5rem);
  color: rgb(3, 67, 131);
  /* line-height: 5rem; */
  max-width: 600px;
  margin: 0 auto;
  /* Center the heading horizontally */
  text-align: center;
  animation: slideIn 1s ease-out forwards;
}
@keyframes slideIn {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.secondary-heading {
  font-size: clamp(1.4rem, 2.5vw, 2rem);
  color: rgb(3, 67, 131);
  /* line-height: 5rem; */
  max-width: 600px;
  margin: 0 auto;
  /* Center the heading horizontally */
  text-align: center;
  /* Align text to the center */
}



.primary-text {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 100%;
  color: #6a6a6a;
  margin: 1.5rem 0;
  padding: 20px;
  line-height: 1.5;
  /* Set line height to 1.5 */
}

.secondary-button {
  padding: 1.5rem 3rem;
  /* Increased padding for a larger button */
  /*background-color: #fe9e0d;*/
  /*background-color: #057417;*/
  /*background-color: rgb(3, 67, 131);*/

  background-color: rgba(3, 67, 131,0);
  outline: auto;
  border-radius: 1rem;
  /* Slightly reduced border-radius for a smoother look */
  font-size: 1.5rem;
  /* Increased font size for better readability */
  cursor: pointer;
  font-weight: bold;
  color: rgb(3, 67, 131);
  transition: background-color 0.2s, transform 0.2s;
  display: inline-block;
  /* Change to inline-block for better sizing */
  text-decoration: none;
  /* Remove underline by default on anchor tags */
  text-align: center;
  /* Center text horizontally */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(3, 67, 131);
  /* Add subtle box shadow for depth */


}



.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}

.secondary-button:hover {
  background-color: rgb(7, 49, 97);
  color: white;
}

.about-section-container {
  margin-top: 20rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about-background-image-container {
  position: absolute;
  left: -150px;
  z-index: -2;
}

.about-section-image-container {
  flex: 0.9;
  margin-right: 1rem;
}

.about-section-text-container {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.primary-subheading {
  font-weight: 700;
  color: #fe9e0d;
  font-size: 1.15rem;
}

.about-buttons-container {
  margin-top: 2rem;
  display: flex;
}

.watch-video-button {
  margin-left: 2rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: #484848;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.watch-video-button svg {
  font-size: 3rem;
  margin-right: 1rem;
}

.contact-page-wrapper h1 {
  max-width: 900px !important;
}

.contact-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 6rem 0rem;
}

.contact-form-container {
  background-color: white;
  max-width: 700px;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 5rem;
}

.contact-form-container input {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
}

.footer-wrapper {
  margin: 6rem 0rem;
  display: flex;
  margin-top: 10rem;
}

.footer-logo-container {
  max-width: 110px;
}

.footer-icons {
  margin-top: 2.5rem;
}

.footer-icons svg {
  font-size: 1.5rem;
  margin-right: 1.25rem;
  color: #585858;
}

/* .footer-section-one {
  border: 2px solid blue;
} */
.footer-section-two {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.footer-section-columns {
  display: flex;
  flex-direction: column;
  min-width: 190px;
}

.footer-section-columns span {
  margin: 0.25rem 0rem;
  font-weight: 600;
  color: #4c4c4c;
  cursor: pointer;
}

.testimonial-section-bottom {
  margin-top: 2rem;
  background-color: white;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 750px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
}

.testimonial-section-bottom {
  margin: 2rem auto;
}

.testimonial-section-bottom p {
  font-weight: 700;
  color: #515151;
  max-width: 500px;
  font-size: 1.1rem;
  margin: 2rem 0rem;
  line-height: 1.5;
}

.testimonials-stars-container svg {
  margin: 0rem 0.25rem;
  font-size: 1.5rem;
  color: #fe9e0d;
}

.testimonial-section-bottom h2 {
  margin-top: 1.5rem;
}

.work-section-wrapper {
  margin-top: 15rem;
}

.work-section-top p {
  text-align: center;
  max-width: 600px !important;
}

.work-section-top h1 {
  max-width: 700px !important;
}

.work-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.work-section-info {
  width: 290px;
  min-height: 350px;
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #505050;
  margin: 1rem 2rem;
}

.work-section-info h2 {
  margin: 1rem 0rem;
}

.work-section-bottom {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.work-section-info p {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.homeService-section-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  /* Adjust the gap between serv ice items */
  padding: 30px;
}

.homeService-section-bottom {

  width: 400px; 
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  text-align: center;
  margin-bottom: 2rem;
  /* Adjust the margin between service items */
  box-shadow: 0px 8px 5px rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  overflow: hidden;
}
 
.homeService-section-bottom img {
  width: 100%;
  /* Ensure the image fills its container */
  height: 300px;
  border-top-left-radius: 1rem;
  /* Optionally round the image corners */
  border-top-right-radius: 1rem;
  padding-bottom: 5px;
  transition: transform 0.3s;
}

.homeService-section-bottom img:hover{
  transform: scale(1.1);
  margin-bottom: 1rem;

}

.homem-service-primary-heading {
  font-size: 22px;
  color: rgb(3, 67, 131);
  /* line-height: 5rem; */
  max-width: 600px;
  margin: 0 auto;
  /* Center the heading horizontally */
  text-align: center;
  /* Align text to the center */
}

.homeService-section-bottom p {
  margin: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 1.5;
}

.container-overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
 background-color: rgba(3, 67, 131, 0.1);
}

.container-overlay:hover{
  background-color: rgba(0, 0, 0, 0); 
}


.news-visit-more {
  position: absolute;
  

  z-index: 999;

  font-size: 20px;
  max-width: 100%;
  line-height: 1.5;
  /* Set line height to 1.5 */
  text-align: center;
  justify-content: center;
  color: rgb(3, 67, 131);
  border: 1px solid rgb(241, 191, 26);
  font-weight: bold;
  box-shadow: 0px 4px 8px rgb(241, 191, 26);
  margin-top: 2rem;
  padding: 0 0.5rem;
  border-radius: 1rem;
 
}





.news-visit-more:hover{
  color: rgb(241, 191, 26);
  box-shadow: 0px 4px 8px  rgba(3, 67, 131);
  border: 1px solid rgba(3, 67, 131);
  text-decoration: underline;
  background-color: rgb(3, 67, 131);
  border-radius: 0;
 
}


.more-news-visit-more {
  position: relative;
  

  z-index: 999;

  font-size: 20px;
  max-width: 100%;
  line-height: 1.5;
  /* Set line height to 1.5 */
  text-align: center;
  justify-content: center;
  color: rgb(3, 67, 131);
  border: 1px solid rgb(241, 191, 26);
  font-weight: bold;
  box-shadow: 0px 4px 8px rgb(241, 191, 26);
  margin-top: 2rem;
  padding: 0 0.5rem;
  border-radius: 1rem;
 
}





.more-news-visit-more:hover{
  color: rgb(241, 191, 26);
  box-shadow: 0px 4px 8px  rgba(3, 67, 131);
  border: 1px solid rgba(3, 67, 131);
  text-decoration: underline;
  background-color: rgb(3, 67, 131);
  border-radius: 0;
 
}
@media (max-width: 900px) {
  .homeService-section-bottom {
    flex-basis: 45%;
    /* For smaller screens, each item takes up 45% width */
  }

  .homeService-section-bottom img {
    width: 100%;
    /* Ensure the image fills its container */
    height: 80%;
   
  }
  .homeService-section-wrapper {
    padding: 20px;
  }
}

@media (max-width: 600px) {
  .homeService-section-bottom {
    flex-basis: 100%;
    /* On even smaller screens, each item takes up 100% width (full width) */
  }
}


/*Making responsive*/
@media (max-width: 1000px) {
  /*
.navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
*/.news-visit-more{
  position: relative;
  width: 40%;
}

  .primary-button {
    font-size: 1rem;
  }

  .home-bannerImage-container {
    max-width: 600px;
  }
}

@media (max-width: 800px) {

  /*
  .nav-logo-container {
    max-width: 140px;
  }
  .navbar-links-container {
    display: none;
  }
  .navbar-menu-container {
    display: flex;
  }*/
  .home-bannerImage-container {
    max-width: 450px;
  }

  .home-about-container,
  .about-section-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding: 5rem;
  }

  .about-section-container {
    flex-direction: column;
  }

  .home-about-image-section,
  .about-section-image-container {
    width: 100%;
    max-width: 400px;
    display: flex;
    justify-content: center;
    /* Center the content horizontally */
    margin: 0 auto;
    /* Automatically center the container */
  }

  .primary-heading {
    text-align: center;
    max-width: 90%;
  }

  .primary-text {
    text-align: justify;

   
  }

  .home-text-section,
  .about-section-text-container {
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }

  .secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }

  .about-section-container {
    margin-top: 5rem !important;
  }

  .about-buttons-container {
    justify-content: center;
    flex-direction: column;
  }

  .primary-subheading {
    text-align: center;
  }

  .watch-video-button {
    margin-left: 0rem !important;
    margin-top: 1rem;
    font-size: 1rem !important;
  }

  .watch-video-button svg {
    margin-right: 0.5rem !important;
  }

  .about-section-image-container {
    margin-right: 0rem !important;
  }

  .work-section-wrapper {
    margin-top: 5rem !important;
  }

  .work-section-bottom {
    margin-top: 1rem !important;
  }

  .contact-form-container {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;
  }

  .contact-form-container input {
    font-size: 1rem !important;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.8rem !important;
    text-align: center;
  }

  .footer-wrapper {
    flex-direction: column;
  }

  .footer-section-two {
    flex-direction: column;
    margin-top: 2rem;
  }

  .footer-section-columns {
    margin: 1rem 0rem;
  }

  .App {
    max-width: 95vw;
  }
}

@media (max-width: 600px) {

  .home-bannerImage-container,
  .about-background-image-container {
    display: none;
  }
}