.head-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: rgb(219, 225, 231);
    color: #fff;
    font-family: 'Arial', sans-serif;
    width: 100%;
  }
  
  .head-title {
    font-size: 5rem;
    margin-bottom: 20px;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
  }
  
  .button-container {
    display: flex;
    gap: 20px;
  }
  
  .admin-button {
    padding: 15px 30px;
    font-size: 1.8rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    width: 150px;
  }
  
  .admin-button:focus {
    outline: none;
  }
  
  .add-button {
    background-color:rgb(3, 67, 131);
    color: white;
  }
  
  .edit-button {
    background-color:rgb(3, 67, 131);
    color: white;
  }
  
  .admin-button:hover {
    transform: scale(1.05);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  }
  