.contact-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 6rem;
    margin-top: 4rem;
    margin-bottom: 2rem;
}


.contact-banner {
    position: relative;
    height: 550px;
    overflow: hidden;
    align-items: center;
}


.contact-banner::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    /* Adjust the color and opacity as needed */
    z-index: 999;
    /* Ensure the overlay is above the image */
}


.contact-banner img {
    width: 100%;

    object-fit: fill;
    height: 550px;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    position: relative;
    /* Ensure the image stays above the overlay */
    z-index: 2;
    /* Ensure the image is above the overlay */
}



.contact-info {
    width: 100%;
    padding: 10px;

    border-radius: 10px;
    align-items: center;
}

.info-item-social {
    padding-top: 5px;
    color: rgb(3, 67, 131);
    font-size: 30px;
    margin-right: 15px;
    transition: transform 0.3s ease;
    align-items: center;
}

.info-item-social:hover {
    transform: translateY(-5px);
}

.contact-info h2 {
    color: #333;
}

.info-item {
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: #333;
}



.icon {
    margin-right: 10px;
    color: rgb(3, 67, 131);
    font-size: 20px;
}

.phone {
    margin-right: 10px;
    margin-left: 10px;
    color: rgb(3, 67, 131);
    font-size: 20px;
}

.social-media {
    margin-top: 10px;
}

.social-media a {
    margin-right: 10px;
    color: #555;
}

.map-container {
    width: 60%;
    max-width: 600px;
    border-radius: 10px;
    overflow: hidden;
}

.country-map {
    width: 100%;
    display: block;
}

.column { 
    flex-direction: column;
}

.comment-section {
    width: 30%;
    padding: 20px;
    background-color: rgba(221, 221, 238, 0.192);
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border: 1px solid #ccc;
}

.comment-section h2 {
    color: #333;
    padding-bottom: 10px;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
}

input,
textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.comment-button {
    padding: 10px 20px;
    background-color: rgb(3, 67, 131);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
}

button:hover {
    background-color: rgb(3, 52, 102);
}

@media (max-width: 1000px) {
    .contact-container {
        flex-direction: column;
        /* Stack elements vertically on small devices */
        align-items: center;
        /* Center items horizontally */
        max-width: 100%;
        /* Ensure content fills the screen */
        flex: 1;
        /* Allow content to expand to fill available space */
        margin-top: 0;
        /* Remove top margin */
        margin-bottom: 0;
        /* Remove bottom margin */
        height: 100%;
        /* Occupy full height of viewport */
    }

    .comment-section {
        width: 90%;
        margin: 20px;

    }

    .map-container {
        margin-top: 10px;
        width: 90%;
    }


    .contact-banner {
        position: relative;
        height: 300px;
        overflow: hidden;
        align-items: center;
    }



    .contact-banner img {
        width: 100%;
        object-fit: fill;
        height: 300px;
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
        position: relative;
        /* Ensure the image stays above the overlay */
        z-index: 2;
        /* Ensure the image is above the overlay */
    }

}