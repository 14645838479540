/* grid c7 */
.grid-c7-content {
  margin-top: -42px;
  margin-bottom: -128px;
}
.progress-bar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress-bar .percent {
  position: relative;
}
.progress-bar svg {
  position: relative;
  width: 210px;
  height: 210px;
  transform: rotate(-90deg);
}
.progress-bar svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: var(--clr-jet);
  stroke-width: 20;
  stroke-linecap: round;
}
.progress-bar svg circle:last-of-type {
  stroke-dasharray: 625px;
  stroke-dashoffset: calc(625px - (625px * var(--percent)) / 168);
  stroke: var(--clr-pumpkin);
}
.progress-bar .number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.progress-bar .number h3 {
  font-weight: 200;
  font-size: 20px;
  font-weight: 500;
}
.progress-bar .number h3 span {
  font-size: 14px;
}

/* grid c7 */
.grid-c7-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.grid-c7-content .progress-bar {
  margin-left: -46px;
}
.grid-c7-content .data-list {
  margin-left: -20px;
}
.grid-c7-content .data-item {
  margin-bottom: 20px;
}
.grid-c7-content .data-item-value {
  margin-top: 8px;
  display: block;
  font-weight: 600;
}
