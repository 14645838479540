/* Basic Styling */
.news-page-style {
  font-family: Arial, sans-serif;
  color: #333;

}

.the-banner {
  position: relative;
  height: 550px;
  overflow: hidden;


}

.the-banner::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(3, 67, 131, 0.5);
  /* Adjust the color and opacity as needed */
  z-index: 999;
  /* Ensure the overlay is above the image */
}

.news-section-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  /* Adjust the gap between service items */
  padding: 30px;
}


.news-section-bottom {

  position: relative;
  width: 400px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;
  /* Adjust the margin between service items */
  box-shadow: 0px 8px 5px rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  overflow: hidden;
}

.news-section-bottom img {
  width: 400px;
  /* Ensure the image fills its container */
  height: 300px;
  padding-bottom: 5px;
  transition: transform 0.3s;
}
 
.news-section-bottom img:hover {
  transform: scale(1.1);
  opacity: 80%;
  z-index: 999;
}

.more-news-title {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: clamp(1.4rem, 2.5vw, 2rem);
  color: white;
  background-color: rgba(0, 0, 0, 0.336);
  border-radius: 1rem;
  /* line-height: 5rem; */
  max-width: 600px;
  margin: 0 auto;
  /* Center the heading horizontally */
  text-align: center;
  /* Align text to the center */

}


.latest-news-title {
  font-size: clamp(1.4rem, 2.5vw, 2rem);
  color: rgb(3, 67, 131);
  background-color: rgba(241, 191, 26, 0.5);
  /* line-height: 5rem; */
  max-width: 600px;
  margin: 0 auto;
  /* Center the heading horizontally */
  text-align: center;
  /* Align text to the center */

}

.news-section-bottom p {
  margin: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 1.5;
}


.the-banner img {
  width: 100%;
  object-fit: cover;
  height: 550px;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;

}

.date-container {
  display: flex;
  align-items: center;
}

.date-icon {
  margin-right: 5px;
}

.date-text {
  font-size: 16px;
  color: #333;
}


.the-banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: clamp(5rem, 2.5vw, 6.5rem);
  font-weight: bold;
  color: rgb(255, 255, 255);
  text-shadow: 0px 6px 10px rgba(0, 0, 0, 0.7);
  text-decoration: wavy;
  z-index: 2;
}



.the-content-container {
  display: flex;
  justify-content: space-between;
  margin: 50px 0;
}

.the-left-section img {
  width: 600px;
  max-width: 700px;
  height: 500px;
  margin-left: 2rem;
}

.the-right-section {
  flex: 1;
  padding: 0 20px;
  line-height: 1.5;
}

.the-team-member {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.the-member-info {
  margin-left: 20px;
}

.the-core-values {
  margin-top: 50px;
}


/*Styling for Latest news content*/
.news-content-container {
  display: flex;
  justify-content: space-between;
  gap: 5rem;
  margin: 40px;

}

.news-left-section {
  display: flex;
  position: relative;
  width: 550px;
  max-width: 700px;
  height: 300px;
  margin-left: 2rem;
}



.news-left-section img {
  width: 100%;
  height: 100%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
}

.news-right-section {
  flex: 1;
  padding: 0 20px;
}

/* Styling for Links */
.news-a {
  padding-top: 1rem;
  text-decoration: none;
  color: #333;
  line-height: 1.5;
  text-align: left;
}

.news-a:hover {
  text-decoration: underline;
  color: #007bff;
}

.news-p {
  padding-top: 20px;
  text-align: justify;
  padding-bottom: 5px;
}

.more-news-p {
  -webkit-line-clamp: 2;
}
.more-news-p:hover{
  text-decoration: underline;
}
@media(max-width:1000px) {
  .news-content-container {
    display: flex;
    position: relative;
    flex-direction: column;
   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
   padding-top: 0;
   padding-bottom: 10px;
  }

  .news-left-section {

    width: 100%;
    max-width: 700px;
    height: 300px;
    margin: 10px;
    margin-left: 0;
  }

  .the-banner {
    height: 300px;

  }

  .the-banner img {
    height: 300px;

  }

  .news-section-wrapper {
    padding: 20px;
  }

  
.news-section-bottom img {

  height: 200px;
  
}

.news-content-container {

  margin: 20px;

  
}


.news-left-section {
  width: 100%;
  height: 200px;
}
}