/* Gallery.css */

.gallery-container {
    width: 100%;
    max-width: 600px; /* Adjust max-width as needed */
    margin: 0 auto;
  }
  
  .image-gallery-image {
    border-radius: 10px;
    overflow: hidden;
  }
  
  .image-gallery-image img {
    border-radius: 10px;
  }
  
  .image-gallery-bullets {
    bottom: 20px;
  }
  
  .image-gallery-bullet {
    background-color: #ccc; /* Bullet color */
  }
  
  .image-gallery-bullet.active {
    background-color: #333; /* Active bullet color */
  }

  @media(max-width:900px){
    .gallery-container {
      padding:10px;
    }
  }

 