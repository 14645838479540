/* grid c5 */
.grid-c5 .grid-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.grid-c5 .grid-item:last-child {
  margin-bottom: 0;
}
.grid-c5 .grid-item-l {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.grid-c5 .grid-item-l .icon {
  background-color: rgb(254, 30, 0, 0.15);
  width: 32px;
  height: 32px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.grid-c5 .grid-item-l .icon img {
  width: 16px;
}
.grid-c5 .grid-item-l .text {
  color: var(--clr-silver-v1);
}
.grid-c5 .grid-item-l .text span {
  color: #8a8587;
  font-size: 15px;
}
.grid-c5 .grid-item-r span {
  font-weight: 600;
}
