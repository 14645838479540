/* Basic Styling */
.partners-page-style {
  font-family: Arial, sans-serif;
  color: #333;

}

.partners-banner img {
  width: 100%;
  object-fit: cover;
  height: 550px;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;

}

.partners-banner {
  position: relative;
  height: 550px;
  overflow: hidden;


}

.partners-section-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  /* Adjust the gap between service items */
  padding: 30px;
}


.partners-section-bottom {

  width: 300px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;
  /* Adjust the margin between service items */
  box-shadow: 0px 8px 5px rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
}

.partners-section-bottom img {
  width: 100%;
  /* Ensure the image fills its container */
  height: 200px;
  padding-bottom: 5px;
}

.partners-tilte {
  margin-top: 1rem;
  color: rgb(3, 67, 131);
}

.partners-section-bottom p {
  margin: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 1.5;
}


.slogan-banner img {
  width: 100%;
  object-fit: cover;
  height: 550px;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  opacity: 80%;

}



.slogan-text {
  position: absolute;
  top: 50%; 
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: clamp(2rem, 3vw, 3rem);
  font-weight: bold;
  color: rgb(241, 191, 26);
  text-shadow: 0px 6px 10px rgba(1, 5, 0, 0.8);
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  /* Added padding for better readability */
  border-radius: 10px;
  /* Rounded corners */
  animation: glow 2s infinite alternate;
  /* Added animation for glowing effect */

  
}

@keyframes harmonicText {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.slogan-text {
  animation: harmonicText 1.5s ease-in-out infinite;
}

.slogan-text:hover {
  animation-play-state: paused !important;
}



.the-content-container {
  display: flex;
  justify-content: space-between;
  margin: 50px 0;
}

.the-left-section img {
  width: 600px;
  max-width: 700px;
  height: 500px;
  margin-left: 2rem;
}

.the-right-section {
  flex: 1;
  padding: 0 20px;
  line-height: 1.5;
}

.the-team-member {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.the-member-info {
  margin-left: 20px;
}

.the-core-values {
  margin-top: 50px;
}


/*Styling for Latest news content*/
.home-news-content-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  /* Adjust the gap between service items */
  padding: 30px;
}


.home-news-section-bottom {
  position: relative;
  width: 400px;
  height: 300px;
  background-color: white;
  display: inline-block;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;
  /* Adjust the margin between service items */
  box-shadow: 0px 8px 5px rgba(0, 0, 0, 0.1);
}

.home-news-image-container {
  position: relative;
}

.home-news-section-bottom:hover {
  background-color: #051a31;
}

.tot-cont {
  position: relative;
  width: 100%;
  /* Adjust as needed */
  max-width: 800px;
  /* Adjust as needed */
  margin: 0 auto;
  /* Center the container */
}









.home-news-section-bottom-img {
  width: 100%;
  /* Ensure the image fills its container */
  height: 100%;
  object-fit: fill;
}

.home-news-section-bottom-img:hover {
  opacity: 80%;
}



.home-news-snippet {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 999;

  font-size: 20px;
  max-width: 100%;
  color: #0c0c0c;
  line-height: 1.5;
  /* Set line height to 1.5 */
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.home-news-snippet-visit-more {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 999;

  font-size: 20px;
  max-width: 100%;
  color: #0c0c0c;
  line-height: 1.5;
  /* Set line height to 1.5 */
  text-align: center;
  color: rgb(3, 67, 131);
  border: 1px solid rgb(241, 191, 26);
  font-weight: bold;
  box-shadow: 0px 4px 8px rgb(241, 191, 26);
  padding: 0 0.5rem;
  border-radius: 1rem;
 
}




.home-news-snippet a{
  color: white;
  text-decoration: underline;
}
.home-news-snippet a:hover{
  color: rgb(241, 191, 26);
 
}

.home-news-snippet-visit-more:hover{
  color: rgb(241, 191, 26);
  box-shadow: 0px 4px 8px  rgba(3, 67, 131);
  border: 1px solid rgba(3, 67, 131);
  text-decoration: underline;
  background-color: rgb(3, 67, 131);
  border-radius: 0;
 
}

.home-news-snippet:hover {
  text-decoration: underline;
  background-color: rgba(3, 67, 131, 0.418);
  text-shadow: 0px 6px 10px rgba(0, 0, 0, 0.5);
}

.partners-tilte {
  margin-top: 1rem;
  color: rgb(3, 67, 131);
}

/* Styling for Links */
.home-news-a {
  padding-top: 1rem;
  text-decoration: none;
  color: #333;
  line-height: 1.5;
  text-align: left;
}

.home-news-a:hover {
  text-decoration: underline;
  color: #007bff;
}

.home-news-p {
  text-align: justify;
}

.home-more-news-p {
  -webkit-line-clamp: 2;
}

@media(max-width:900px) {
  .home-news-content-container {

    padding: 20px;
  }

  .partners-section-wrapper {
    padding: 20px;
  }
}