.mission-vision-container {
    margin-top: 40px;
    margin: 10px;
    padding: 10px;
  }
  
  .mission-vision-section {
    background: linear-gradient(0deg,  rgb(124, 179, 235),rgb(1, 24, 46));
    border-radius: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 2rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
    min-height: 380px;
  }
  
  .mission-vision-section:hover {
    transform: translateY(-1rem);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background: linear-gradient(0deg, rgb(1, 24, 46), rgb(117, 179, 241));
  }
  
  .mission-vision-section img {
    max-width: 100px;
    margin-bottom: 1rem;
  }
  
  .mission-vision-section h3 {
    font-size: 2rem;
    color: rgb(255, 255, 255);
    margin-bottom: 1rem;
  }
  
  .mission-vision-section p {
    font-size: 1.6rem;
    color: rgb(255, 255, 255);
    line-height: 1.6;
    text-align: justify;
  }
  
  @media (min-width: 900px) {
    .mission-vision-container {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-top: 40px;
      margin: 40px;
      padding: 30px;
    }
  
    .mission-vision-section {
      flex: 1;
      margin: 1rem;
      padding: 2rem;
      max-width: 400px;
    }
  }
  