/* Global Styles */
*,
*::after,
*::before {
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%; /* Sets default font size to 10px */
}

body {
  font-size: 1.6rem; /* Sets base font size to 16px */
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: rgb(255, 255, 255);
}

/* Navbar Styles */
.nav {
  position: fixed; /* Make navbar fixed at the top */
  top: 0;
  left: 0;
  width: 100%; /* Span the entire width of the viewport */
  z-index: 1000; /* Ensure navbar is above other content */
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 9vh;
  background: rgba(3, 67, 131, 0.8);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.nav a:hover {
  color: rgb(241, 191, 26);
}

.nav__brand {
  display: flex;
  position: relative;
  text-transform: capitalize;
  align-items: center;
}



.brand_title_short {
  display: none;
}

.nav__brand img {
  height: 60%;
  width: 60%;
}

.nav__brand a {
  align-items: center;
  text-align: center;
}

.nav__menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 2rem;
}

.nav__toggler {
  display: none;
}

.nav__toggler div {
  width: 2.5rem;
  height: 0.2rem;
  margin: 0.4rem;
  background: rgb(204, 204, 204);
  transition: 0.3s ease-in;
}

@media screen and (max-width: 768px) {
  .nav__toggler {
    display: block;
    cursor: pointer;
  }

  .brand_title_short {
    display: flex;
    text-transform: uppercase;
  }

  .brand_title {
    display: none;
  }

  .nav__menu {
    position: fixed;
    top: 8vh;
    right: 0;
    height: 50%;
    width: 100%;
    background: rgba(3, 67, 131, 0.8);
    flex-direction: column;
    transform: translateX(100%);
    transition: 0.5s ease-in;
    align-items: start;
    padding-left: 40px;
  }
  
}

/* Active Class */
.nav__active {
  transform: translateX(0%);
}

/* Toggle Icon Animation */
.toggle .line1 {
  transform: rotate(-45deg) translate(-4px, 5px);
}

.toggle .line2 {
  opacity: 0;
}

.toggle .line3 {
  transform: rotate(45deg) translate(-4px, -5px);
}

/* Dropdown Styles */
.nav__item {
  position: relative;
}

.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgba(3, 67, 131, 0.8);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1000; /* Ensure dropdown is above other content */
}

.nav__item:hover .dropdown {
  display: block;
}

.dropdown__item {
  padding: 1rem 2rem;
  white-space: nowrap; /* Prevent line breaks within items */
}

.dropdown__link {
  text-decoration: none;
  color: rgb(255, 255, 255);
  display: block;
  width: 100%; /* Ensure the link spans the full width of the dropdown item */
}

.dropdown__link:hover {
  color: rgba(241, 191, 26, 0.8);
}
