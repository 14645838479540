.update-user-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .update-user-form {
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    min-width: 350px;
  }
  
  .admin-icon {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }
  
  h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .upd-admin-input {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .upd-admin-button {
    width: 100%;
    padding: 10px;
    background-color: rgb(3, 67, 131);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .upd-admin-button:hover {
    background-color: rgb(3, 26, 49);
  }
  
  .error-message {
    color: red;
    margin: 10px 0;
  }
  
  .success-message {
    color: green;
    margin: 10px 0;
  }
  